html {
  display: flex;
  justify-content: center;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
}
body {
  background: #f3f3f3;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  height: 100vh;
  width: 100vw;
  max-width: 490px;
  margin: 0;
  padding: 0;
  background-color: #f3f3f3;
}
@font-face {
  font-family: 'Larsseit';
  src: url('/fonts/Larsseit-Light.otf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
select:focus,
textarea {
  font-size: 16px;
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

button {
  background-color: blue;
  color: white;
  height: 30px;
  border-radius: 5px;
  border: none;
  padding: 1% 2%;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: medium;
}
